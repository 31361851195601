export default {
  TYPES: [
    { label: 'Món chính', value: 'main_dish' },
    { label: 'Đồ uống', value: 'beverage' },
    { label: 'Tráng miệng', value: 'dessert' }
  ],
  SIZES: [
    { label: 'Lớn', value: 'L' },
    { label: 'Trung bình', value: 'M' },
    { label: 'Nhỏ', value: 'S' }
  ],
  MANAGE_PAGES: [
    { label: 'Quản lý đơn hàng F & B', key: 'cashier', name: 'ManageCashier', goback: 'Restaurant' },
    { label: 'Đầu bếp quản lý gọi món', key: 'cook', name: 'ManageCook', goback: 'Restaurant' },
    { label: 'Thống kê đơn hàng', key: 'statistic', name: 'ManageStatistic', goback: 'Restaurant' },
    { label: 'Quản lý tài khoản', key: 'account', name: 'ManageAccount', goback: '' }
  ],
  MANAGE_CART_STATUS: [
    { label: 'Tổng đơn hàng', key: 'all', value: '', type: 'primary' },
    { label: 'Đơn hàng mới', action: 'Làm mới', key: 'new', value: 'new', type: 'info' },
    { label: 'Đang xử lý', action: 'Đang xử lý', key: 'progressing', value: 'progressing', type: 'info' },
    { label: 'Chưa giao', action: 'Chưa giao', key: 'not_delivery', value: 'not_delivery', type: 'info' },
    { label: 'Đang giao', action: 'Giao hàng', key: 'is_delivering', value: 'is_delivering', type: 'info' },
    { label: 'Đã giao', action: 'Đã giao', key: 'delivered', value: 'delivered', type: 'info' },
    { label: 'Đã hủy', divided: true, action: 'Hủy đơn hàng', key: 'cancelled', value: 'cancelled', type: 'danger' },
    { label: 'Trả lại', divided: true, action: 'Trả lại', key: 'refund', value: 'refund', type: 'danger' },
    { label: 'Đã hoàn thành', divided: true, action: 'Hoàn thành', key: 'done', value: 'done', type: 'success' }
  ],
  MANAGE_PAYMENT_STATUS: [
    { label: 'Tất cả', key: 'all', value: '', type: 'warning' },
    { label: 'Chưa thanh toán', action: 'Thanh toán', action_value: 'paid', key: 'open', value: 'open', type: 'warning' },
    { label: 'Đã thanh toán', action: 'Hoàn tiền', key: 'paid', action_value: 'refund', value: 'paid', type: 'warning' },
    { label: 'Hoàn tiền', key: 'refund', value: 'refund', type: 'warning' }
  ],
  SUBSCRIPTION_STATUS: [
    { label: 'Chưa xử lý', action: 'Chưa xử lý', key: '', value: '', type: 'info' },
    { label: 'Đang xử lý', action: 'Đang xử lý', key: 'progressing', value: 'progressing', type: 'primary' },
    { label: 'Đã xử lý', action: 'Đã xử lý', key: 'completed', value: 'completed', type: 'success' }
  ]
}
