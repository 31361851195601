const getters = {
  opened: (state) => state.sidebar.opened,
  withoutAnimation: (state) => state.sidebar.withoutAnimation,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  language: (state) => state.public.language,
  constant: (state) => state.public.constant,
  accountOrg: (state) => state.public.accountOrg,
  reloadConstant: (state) => state.public.reloadConstant,
  reloadArtifactGroup: (state) => state.artifact.reloadArtifactGroup,
  reloadFoodGroup: (state) => state.food.reloadFoodGroup
}
export default getters
