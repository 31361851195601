import request from '@/utils/request'
import Cookies from 'js-cookie'
import ENDPOINT from '@/config/endpoint'

export function login(data) {
  return request({
    url: ENDPOINT.AUTH,
    method: 'post',
    data
  })
}

export function updateLoginInfo(data) {
  Cookies.set('login_info', data)
}

export function loginInfo() {
  try {
    return JSON.parse(Cookies.get('login_info'))
  } catch (_) {
    return null
  }
}

export function logout() {
  Cookies.remove('login_info')
  window.location.reload()
}
