<template>
  <div class="has-logo">
    <sidebar-logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routers"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarLogo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
// import { loginInfo } from '@/services/auth'

export default {
  name: 'Sidebar',
  components: {
    SidebarLogo,
    SidebarItem
  },
  computed: {
    ...mapState({
      opened: (state) => state.sidebar.opened
    }),
    routers() {
      return this.$router.options.routes.find((r) => r.path === '/').children
    },
    activeMenu() {
      return this.$route.path.split('/').slice(0, 2).join('/')
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.opened
    }
  }
}
</script>

<style lang="scss" scoped></style>
