import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { loginInfo } from '@/services/auth'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// get admin info + notifycation + refresh token

router.beforeEach(async (to, from, next) => {
  window.scroll(0, 0)
  // start progress bar
  NProgress.start()
  // set page title
  if (to.meta.auth && !loginInfo() && to.name !== 'Login') {
    router.push('/login')
  } else if (to.name === 'Login' && loginInfo()) {
    const user = loginInfo().auth_user
    if (user.staff_type === 'cook') {
      router.push({ name: 'ManageCook' })
    } else if (user.staff_type === 'cashier') {
      router.push({ name: 'ManageCashier' })
    } else {
      router.push('/')
    }
  } else {
    const user = loginInfo() ? loginInfo().auth_user : null
    if (user && to.meta.auth && !to.meta.roles.includes(user.staff_type)) {
      router.push({ name: 'Forbidden' })
    } else {
      next()
    }
  }
  NProgress.done()
})
