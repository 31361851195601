<template>
  <div
    v-loading.fullscreen.lock="callingAPI"
    :class="classObj"
    class="app-wrapper"
  >
    <div v-if="opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div class="fixed-header">
        <navbar />
      </div>
      <app-main v-if="constant" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { loginInfo } from '@/services/auth'
import { getConstant } from '@/services/public'
import AppMain from './components/AppMain'
import Sidebar from './components/Sidebar/index'
import Navbar from './components/Navbar'
import { getAccountOrg } from '@/utils/filters'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Sidebar,
    Navbar
  },
  data() {
    return {
      callingAPI: false
    }
  },
  computed: {
    ...mapGetters(['constant', 'reloadConstant', 'language']),
    ...mapState({
      opened: (state) => state.sidebar.opened,
      withoutAnimation: (state) => state.sidebar.withoutAnimation
    }),
    classObj() {
      return {
        hideSidebar: !this.opened,
        openSidebar: this.opened,
        withoutAnimation: this.withoutAnimation
      }
    }
  },
  watch: {
    reloadConstant: 'handleReload',
    language: 'handleReload'
  },
  mounted() {
    this.loadConstant()
  },
  methods: {
    ...mapActions('public', ['updateConstant', 'updateAccountOrg']),

    loadConstant(loading = true) {
      if (!loginInfo()) return
      this.callingAPI = loading
      this.updateAccountOrg(getAccountOrg())
      getConstant({
        local: this.language
      }).then((response) => {
        const constants = response.constants
        const keys = Object.keys(constants)
        keys.forEach(key => {
          constants[key] = constants[key].map((c, index) => {
            const translation = c.translations ? c.translations.find((tr) => tr.locale === this.language) : {}
            if (translation) {
              return {
                ...constants[key][index],
                locale: translation.locale || constants[key][index].locale,
                name: translation.name || constants[key][index].name
              }
            }
            return constants[key][index]
          })
        })
        this.updateConstant({
          ...response,
          constants: constants
        })
        this.callingAPI = false
      })
    },

    handleClickOutside() {
      this.$store.dispatch('sidebar/closeSideBar', { withoutAnimation: false })
    },

    handleReload() {
      this.loadConstant(false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
  .main-container {
    .app-main {
      overflow: auto;
    }
  }
}
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2001;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}
</style>
