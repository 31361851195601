import router from '@/router'
import { logout } from '@/services/auth'

export function handleResponseErrorMessage(err) {
  if (err.response === undefined) {
    return err
  } else if (err.response.status === 401) {
    logout()
  } else if (err.response.status === 403) {
    router.push({ name: 'Forbidden' })
  } else if (err.response.status === 500) {
    // router.push({ name: 'ServerError' })
  } else if (err.response.status === 502) {
    router.push({ name: 'BadGateway' })
  } else {
    return err
  }
}
