const state = {
  constant: null,
  language: 'vi',
  accountOrg: null,
  reloadConstant: null
}

const getters = {
  constant(state) {
    return state.constant
  },
  language(state) {
    return state.language
  },
  accountOrg(state) {
    return state.accountOrg
  },
  reloadConstant(state) {
    return state.reloadConstant
  }
}

const mutations = {
  UPDATE_CONSTANT(state, constant) {
    state.constant = constant
  },
  UPDATE_LANGUAGE(state, language) {
    state.language = language
  },
  UPDATE_ACCOUNT_ORG(state, accountOrg) {
    state.accountOrg = accountOrg
  },
  UPDATE_RELOAD_CONSTANT(state, reloadConstant) {
    state.reloadConstant = reloadConstant
  }
}

const actions = {
  updateConstant({ commit }, constant) {
    commit('UPDATE_CONSTANT', constant)
  },
  updateLanguage({ commit }, language) {
    commit('UPDATE_LANGUAGE', language)
  },
  updateAccountOrg({ commit }, accountOrg) {
    commit('UPDATE_ACCOUNT_ORG', accountOrg)
  },
  updateReloadConstant({ commit }, reloadConstant) {
    commit('UPDATE_RELOAD_CONSTANT', reloadConstant)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
