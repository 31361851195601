<template>
  <div style="padding: 0 15px" @click="toggleClick">
    <i :class="isActive ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger-container {
  display: flex;
  align-items: center;
  i {
    font-size: 28px;
  }
}
</style>
